import React from "react";
import Favourating from "../../assets/images/problem-solution/problem-img-one.svg";
import Rating from "../../assets/images/problem-solution/problem-img-two.svg";
import Goldenbites from "../../assets/images/problem-solution/problem-img-three.svg";
import Profile from "../../assets/images/problem-solution/ps-1.svg";
import Connections from "../../assets/images/problem-solution/ps-2.svg";
import Guidence from "../../assets/images/problem-solution/pb-4.svg";
import Locate from "../../assets/images/problem-solution/pb-5.svg";
import Travel from "../../assets/images/problem-solution/pb-6.svg";

import "./index.scss";

function ProblemSolution() {
  return (
    <>
      <div className="container-fluid  problem-solution-container">
        {/* <div className="row problem-section-main">
          <h1>Lacking confidence in traditional star ratings?</h1>
        </div> */}

        {/* <div className="row problems-row">
          <div className="col-lg-6 problem-text">
            <div className="row">
              <div className="col-lg-12  problem-img">
                <div>
                  <img src={Rating} alt="" className="img-fluid cc" />
                </div>

                <div>
                  <img src={Favourating} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <h1>Trustworthy opinions</h1>
            </div>
            <div className="row">
              <p>
                Get recommendations from people you trust because their opinions
                matter the most.
              </p>
            </div>
          </div>
          <div className="col-lg-6 problem-text">
            <div className="row">
              <div className="col-lg-12  problem-img">
                <img src={Goldenbites} alt="" className="img-fluid" />
              </div>
            </div>

            <div className="row">
              <h1>GoldenBites awards</h1>
            </div>
            <div className="row">
              <p>
                Explore handpicked dishes awarded with GoldenBites. It’s not a
                score but a distinction.
              </p>
            </div>
          </div>
        </div> */}

        <div className="row problem-section-main">
          <h1>Top recommendations don't suit your taste?</h1>
        </div>

        <div className="row problems-row">
          <div className="col-lg-6 problem-text">
            <div className="row">
              <div className="col-lg-12  problem-img">
                <img src={Profile} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <h1>Personalize profile</h1>
            </div>
            <div className="row">
              <p>
                Shape your profile to receive recommendations that perfectly
                aligned with your desires.
              </p>
            </div>
          </div>
          <div className="col-lg-6 problem-text">
            <div className="row">
              <div className="col-lg-12  problem-img">
                <img src={Connections} alt="" className="img-fluid" />
              </div>
            </div>

            <div className="row">
              <h1>Personalize connections</h1>
            </div>
            <div className="row">
              <p>
                Effortlessly curate your feed by choosing who to follow for
                personalized content.
              </p>
            </div>
          </div>
        </div>
        <div className="row solution">
          <div className="col-lg-7 solution-text">
            <h1>Need reliable guidance from the experts?</h1>
            <p>
              Follow the popular foodies with our app for trustworthy opinions
              and tailored suggestions.
            </p>
          </div>
          <div className="col-lg-5 solution-image">
            <img src={Guidence} alt="" />
          </div>
        </div>
        <div className="row solution">
          <div className="col-lg-5 solution-image">
            <img src={Locate} alt="" />
          </div>
          <div className="col-lg-7 solution-text">
            <h1>Can’t locate saved dishes when dining out?</h1>
            <p>
              In 'Travel Mode' Favhiker lets you know about the best dishes and
              restaurants as you approach them.
            </p>
          </div>
        </div>
        <div className="row solution">
          <div className="col-lg-7 solution-text">
            <h1>Want to be ready before travelling?</h1>
            <p>
              Select 'Explore Another City' to remotely discover dishes and
              restaurants in any city.
            </p>
          </div>
          <div className="col-lg-5 solution-image">
            <img src={Travel} alt="" />
          </div>
        </div>
      </div>

      {/* ******************************************* */}

      <div className="container-fluid  problem-solution-mobile-container">
        {/* <div className="row problem-section-main">
          <h1>Lacking confidence in traditional star ratings?</h1>
        </div> */}

        {/* <div className="row problems-row">
          <div className="col-lg-6 problem-text">
            <div className="row">
              <h1>Trustworthy opinions</h1>
            </div>
            <div className="row">
              <div className="col-lg-12  problem-img">
                <div>
                  <img src={Rating} alt="" className="img-fluid cc" />
                </div>

                <div>
                  <img src={Favourating} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <p>
                Get recommendations from people you trust because their opinions
                matter the most.
              </p>
            </div>
          </div>
          <div className="col-lg-6 problem-text">
            <div className="row">
              <h1>GoldenBites awards</h1>
            </div>

            <div className="row">
              <div className="col-lg-12  problem-img">
                <img src={Goldenbites} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <p>
                Explore handpicked dishes awarded with GoldenBites. It’s not a
                score but a distinction.
              </p>
            </div>
          </div>
        </div> */}

        <div className="row problem-section-main">
          <h1>Top recommendations don't suit your taste?</h1>
        </div>

        <div className="row problems-row">
          <div className="col-lg-6 problem-text">
            <div className="row">
              <h1>Personalize profile</h1>
            </div>

            <div className="row">
              <div className="col-lg-12  problem-img">
                <img src={Profile} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <p>
                Shape your profile to receive recommendations that perfectly
                aligned with your desires.
              </p>
            </div>
          </div>
          <div className="col-lg-6 problem-text">
            <div className="row">
              <h1>Personalize connections</h1>
            </div>
            <div className="row">
              <div className="col-lg-12  problem-img">
                <img src={Connections} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <p>
                Effortlessly curate your feed by choosing who to follow for
                personalized content.
              </p>
            </div>
          </div>
        </div>
        <div className="row solution">
          <div className="col-lg-12 solution-text">
            <h1>Need reliable guidance from the experts?</h1>
            <img src={Guidence} alt="" className="img-fluid" />
            <p>
              Follow the popular foodies with our app for trustworthy opinions
              and tailored suggestions.
            </p>
          </div>
        </div>
        <div className="row solution">
          <div className="col-lg-12 solution-text">
            <h1>Can’t locate saved dishes when dining out?</h1>
            <img src={Locate} alt="" className="img-fluid" />
            <p>
              In 'Travel Mode' Favhiker lets you know about the best dishes and
              restaurants as you approach them.
            </p>
          </div>
        </div>
        <div className="row solution">
          <div className="col-lg-12 solution-text">
            <h1>Want to be ready before travelling?</h1>
            <img src={Travel} alt="" className="img-fluid" />
            <p>
              Select 'Explore Another City' to remotely discover dishes and
              restaurants in any city.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProblemSolution;
