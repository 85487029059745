import * as React from "react";
import "./index.scss";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import FavHikerLogo from "../../../assets/images/header/logo.svg";
import MenuIcon from "../../../assets/images/header/menu-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Dropdown from "../../Dropdown/Dropdown";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const profileData = [
  {
    key: "Home",
    value: "Home",
    href: "#home",
    route: "/home",
  },
];

const handleFieldClick = (e: any) => {
  console.log(e.target.value);
};

export default function HelpNavbar(props: any) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleAbout = () => {
    navigate("/about_us");
  };

  const handleProducts = () => {
    navigate("/product-category");
  };

  const handleHelp = () => {
    navigate("/support");
  };

  const handleCareers = () => {
    navigate("/careers");
  };

  const handleDealer = () => {
    navigate("/product_enquiry");
  };

  const handleContact = () => {
    navigate("/contact_us");
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const handleScreen = () => {
    // document.querySelector("body").style.overflow = "auto";
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          style={{ backgroundColor: "black" }}
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <NotificationsIcon />
          {/* </Badge> */}
        </IconButton>
        <p className="para-margin">Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          className="black-background"
          style={{ backgroundColor: "black" }}
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p className="para-margin">Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className="navbar-header"
        style={{
          position: "static",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <Toolbar className="navbar-height">
          <div className="d-flex d-md-none w-100 justify-content-between align-items-center">
            <Button onClick={() => handleHome()}>
              <img src={FavHikerLogo} alt="" />
            </Button>
            <h3 className="header-logo-text">favhiker</h3>
            <Dropdown
              // name={state?.user?.first_name}
              // name={props.user.first_name}
              menuData={profileData}
              imagePath={MenuIcon}
              color="transparent"
              className="pro-btn-main"
              buttonClass="pro-nav-btn pro-acc-btn navbar-mobile-btn"
              menuClass="menu-navbar"
              // endIcon={<DropdownIcon />}
              // endIcon={<DropdownIcon iconColor="white" />}
              spanclass={"span-spacing"}
              customIcon={<LogoutIcon />}
              customText="Logout"
              // customFunction={handleLogout}
              // profileFunction={handleProfile}
              handleFieldClick={handleFieldClick}
              homeRef={props.homeRef}
              scrollToSection={props.scrollToSection}
            />
          </div>

          <div className="d-md-flex d-none w-100 justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <Button className="navbar-logo-btn" onClick={() => handleHome()}>
                <img src={FavHikerLogo} alt="" />
              </Button>
              <h3 className="header-logo-text">favhiker</h3>
            </div>

            <div className="d-flex justify-content-center w-100 align-items-center navbar-btn-container">
              <Button className="navbar-btns" onClick={() => handleHome()}>
                <h4>Home</h4>
              </Button>
              {/* <Button className="navbar-btns" onClick={() => handleAbout()}>
                <h4>About Us</h4>
              </Button>
              <Button className="navbar-btns" onClick={() => handleProducts()}>
                <h4>Core Features</h4>
              </Button>

              <Button className="navbar-btns" onClick={() => handleHelp()}>
                <h4>Help & Support</h4>
              </Button> */}
            </div>
            <div>
              {/* <Button className="download-button">Download now</Button> */}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
