import React, { useEffect, useState } from "react";
import DishImg from "../../assets/images/help/dish.svg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "./index.scss";
import { custom_post, post } from "../../utils/api";
import { useAnimation } from "framer-motion";
import HelpHeader from "./HelpHeader";
import SnackbarAlert from "../../../common/sanckbar";

const faqs = [
  {
    id: 1,
    title: "How does the app suggest the best foods?",
    description:
      "The app suggests the best foods based on the likes and preferences of the Circle members that you have joined. It analyzes the collective preferences and provides personalized recommendations for you.",
  },
  {
    id: 2,
    title: "Can I customise my dietary preferences?",
    description:
      "Yes, you can customize your dietary preferences in the Profile Settings. Simply navigate to the profile page and click on My Taste Profile tab, update your dietary preferences or restrictions to receive more tailored food recommendations.",
  },
  {
    id: 3,
    title: "How can I join a Circle?",
    description:
      "You will join a circle when you sign up based on your location and taste preference. This is an automatic process and it will help users to get the best recommendations available.",
  },
  {
    id: 4,
    title: "How can I change my notification settings?",
    description:
      "To change your notification settings, go to the Notification Settings section in the app's settings page. From there, you can customize your preferences.",
  },
  {
    id: 5,
    title: "What happens if I have dietary restrictions or allergies?",
    description:
      "If you have dietary restrictions or allergies, make sure to update your profile settings accordingly. The app will take your preferences into account and provide recommendations that align with your specific needs and restrictions.",
  },
  {
    id: 6,
    title: "How can I provide feedback or report an issue?",
    description:
      "We value your feedback! To provide feedback or report an issue, please navigate to the Help and Support section in the settings page. You can reach out to our customer support team via the provided contact form, and we will respond to you as soon as possible.",
  },
];

function HelpSection() {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 0.9 && !isVisible) {
        setIsVisible(true);
        controls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls, isVisible]);

  const [contactDropdown, setContactDropdown] = useState(false);
  const [openStates, setOpenStates] = useState(faqs.map(() => false));

  const handleDropdown = (index: number) => {
    setOpenStates((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  const handleContactDropdown = (e: any) => {
    if (contactDropdown) {
      setContactDropdown(false);
    } else {
      setContactDropdown(true);
    }
  };

  const initialFormData = {
    customer_name: "",

    email: "",
    queries: "",

    // confirm_password: "",
  };

  const [formData, setFormData] = useState({
    customer_name: "",

    email: "",
    queries: "",

    // confirm_password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await post("favhiker_enquiries", formData);

      const mailToUser = custom_post("email_user", { ...formData });

      const mailToAdmin = custom_post("email_admin", { ...formData });
      if (response) {
        // Reset the form data
        setFormData(initialFormData);
        console.log("Form submitted successfully");
        alert("Form submitted successfully", "success");
      } else {
        console.error("Form submission failed");
        alert("Form submission failed", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form", "error");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <HelpHeader />
      <div className="row help-section-container">
        <div className="col-xs-12 help-section-main">
          <div className="help-section-content">
            <div className="row d-md-flex d-block justify-content-center align-items-start help-header">
              <div>
                <h1>Help & Support</h1>
                <h2>
                  Favhiker helps customers by addressing their inquiries,
                  concerns, and issues, thereby enhancing their experience with
                  the food app.
                </h2>
              </div>
              {/* <img src={DishImg} alt="" /> */}
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className="d-flex justify-content-between align-items-center help-contact-header">
                  <h3>Get In Touch</h3>
                </div>
                <div
                  className={
                    contactDropdown
                      ? "contact-main hidden"
                      : "contact-main visible"
                  }
                >
                  <div className="d-flex justify-content-center align-items-center name-email-container">
                    <TextField
                      className="form name-field"
                      id="outlined-basic-name"
                      type="text"
                      value={formData.customer_name}
                      onChange={(e: any) =>
                        setFormData({
                          ...formData,
                          customer_name: e.target.value,
                        })
                      }
                      label="Name"
                      placeholder="Name"
                    />
                    <TextField
                      className="form email-field"
                      id="outlined-basic-email"
                      type="email"
                      value={formData.email}
                      onChange={(e: any) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      label="Email"
                      placeholder="Email ID"
                    />
                  </div>

                  <div className="queries-container">
                    <TextField
                      className="form queries-field"
                      id="outlined-basic-queries"
                      type="text"
                      value={formData.queries}
                      onChange={(e: any) =>
                        setFormData({ ...formData, queries: e.target.value })
                      }
                      label="Message"
                      placeholder="Queries"
                    />
                  </div>

                  <div className="text-center">
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <span>SEND QUERIES</span>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpSection;
