import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import FavHikerLogo from "../../assets/images/header/logo.svg";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./Sidebar.scss";
import { Link, useNavigate } from "react-router-dom";

type Anchor = "left";

interface LeftDrawerProps {
  open: boolean;
  onClose: () => void;
  menuItems: {
    key: string;
    value: string;
    route: string;
    href: string;
    image: string;
  }[];
  scrollToSection: any;
  homeRef?: any;
  aboutRef?: any;
  coreRef?: any;
}

const LeftDrawer: React.FC<LeftDrawerProps> = ({
  open,
  onClose,
  menuItems,
  scrollToSection,
  homeRef,
  aboutRef,
  coreRef,
}) => {
  // const navigate = useNavigate();

  const handleNavigation = (e: any) => {
    console.log(e);
  };

  console.log("window", window.location.href.split("/")[3]);

  const handleHome = () => {
    navigate("/home");
  };

  const navigate = useNavigate();
  const list = () => (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
      }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <div
        className="d-flex justify-content-between align-items-center pr-5"
        style={{ backgroundColor: "#fffff" }}
      >
        <Button>
          <img
            src={FavHikerLogo}
            alt=""
            className="malayali-beer-logo-mobile"
          />
        </Button>
        <h3 className="header-logo-text">favhiker</h3>
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{
            padding: "8px", // Adjust the padding as needed
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Divider sx={{ bgcolor: "#363636" }} />
      <List>
        {menuItems.map((item) =>
          item.key === "Home" &&
          window.location.href.split("/")[3] !== "support" ? (
            <>
              <ListItem
                key={item.key}
                disablePadding
                onClick={(e) => {
                  scrollToSection(homeRef);
                }}
              >
                <ListItemButton
                // component={Link} to={item.route}
                >
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ bgcolor: "#363636" }} />
            </>
          ) : item.key === "About Us" ? (
            <>
              <ListItem
                key={item.key}
                disablePadding
                onClick={(e) => {
                  scrollToSection(aboutRef);
                }}
              >
                <ListItemButton component={Link} to={item.route}>
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ bgcolor: "#363636" }} />
            </>
          ) : item.key === "Core Features" ? (
            <>
              <ListItem
                key={item.key}
                disablePadding
                onClick={(e) => {
                  scrollToSection(coreRef);
                }}
              >
                <ListItemButton>
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ bgcolor: "#363636" }} />
            </>
          ) : item.key === "Help & Support" ? (
            <>
              <ListItem
                key={item.key}
                disablePadding
                onClick={() => navigate("/support")}
              >
                <ListItemButton>
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ bgcolor: "#363636" }} />
            </>
          ) : item.key === "Home" &&
            window.location.href.split("/")[3] === "support" ? (
            <ListItem
              key={item.key}
              disablePadding
              onClick={(e) => {
                navigate("/home");
              }}
            >
              <ListItemButton>
                <ListItemText primary={item.value} />
              </ListItemButton>
            </ListItem>
          ) : (
            // item.key === "malayali@malayali.rocks" ? (
            //   <div className="d-flex justify-content-between align-items-center ms-3">
            //     <img src={item.image} alt="" />
            //     <ListItem
            //       key={item.key}
            //       disablePadding
            //       onClick={(e) => {
            //         scrollToSection(item.href);
            //       }}
            //     >
            //       <ListItemButton
            //       // component={Link} to={item.route}
            //       >
            //         <ListItemText
            //           primary={item.value}
            //           className="sidebar-contact-link"
            //         />
            //       </ListItemButton>
            //     </ListItem>
            //   </div>
            // ) :
            <ListItem
              key={item.key}
              disablePadding
              onClick={(e) => {
                scrollToSection(item.href);
              }}
            >
              <ListItemButton
              // component={Link} to={item.route}
              >
                <ListItemText primary={item.value} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      {/* <div className="sidebar-btn-container">
        <Button
          className="dealer-sidebar-btn"
          variant="outlined"
          onClick={() => handleNavigation}
        >
          <span>Find a Dealer</span>
        </Button>
        <Button
          className="contact-btn"
          variant="contained"
          onClick={() => handleNavigation}
        >
          <span>Contact Us</span>
        </Button>
      </div> */}
    </Box>
  );

  return (
    <Drawer anchor="left" open={open} onClose={onClose} className="left-drawer">
      {list()}
    </Drawer>
  );
};

export default LeftDrawer;
