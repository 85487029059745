// PrivacyPolicy.tsx
import "./index.scss";

import React, { useEffect } from "react";
import Navbar from "../Header";

const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <div className="privacy-policy-container">
        <h1>PRIVACY POLICY</h1>

        <p>Last updated November 09, 2023</p>

        <p>
          This privacy notice for Delnie Private Limited (doing business as
          favhiker) ('we', 'us', or 'our'), describes how and why we might
          collect, store, use, and/or share ('process') your information when
          you use our services ('Services'), such as when you:
        </p>

        <ul>
          <li>
            Visit our website at{" "}
            <a
              href="https://www.favhiker.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.favhiker.com
            </a>
            , or any website of ours that links to this privacy notice
          </li>
          <li>
            Download and use our mobile application (favhiker), or any other
            application of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>

        <p>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at{" "}
          <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>.
        </p>

        <h2>SUMMARY OF KEY POINTS</h2>

        <p>
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our table of contents below to
          find the section you are looking for.
        </p>

        <div className="key-points">
          <h3>What personal information do we process?</h3>
          <p>
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you
            use. Learn more about{" "}
            <a href="#personalinfo">personal information you disclose to us</a>.
          </p>

          <h3>Do we process any sensitive personal information?</h3>
          <p>
            We may process sensitive personal information when necessary with
            your consent or as otherwise permitted by applicable law. Learn more
            about <a href="#sensitiveinfo">sensitive information we process</a>.
          </p>

          <h3>Do we receive any information from third parties?</h3>
          <p>We do not receive any information from third parties.</p>

          <h3>How do we process your information?</h3>
          <p>
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so. Learn more about{" "}
            <a href="#infouse">how we process your information</a>.
          </p>

          <h3>
            In what situations and with which parties do we share personal
            information?
          </h3>
          <p>
            We may share information in specific situations and with specific
            third parties. Learn more about{" "}
            <a href="#whoshare">
              when and with whom we share your personal information
            </a>
            .
          </p>

          <h3>How do we keep your information safe?</h3>
          <p>
            We have organisational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorised third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information. Learn more about{" "}
            <a href="#infosafe">how we keep your information safe</a>.
          </p>

          <h3>What are your rights?</h3>
          <p>
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information. Learn more about{" "}
            <a href="#privacyrights">your privacy rights</a>.
          </p>

          <h3>How do you exercise your rights?</h3>
          <p>
            The easiest way to exercise your rights is by{" "}
            <a
              href="https://www.favhiker.com/support"
              target="_blank"
              rel="noopener noreferrer"
            >
              visiting
            </a>
            , or by contacting us. We will consider and act upon any request in
            accordance with applicable data protection laws.
          </p>

          <h3>
            Want to learn more about what we do with any information we collect?
          </h3>
          <p>Review the privacy notice in full.</p>
        </div>

        {/* Continue with other sections */}

        <div className="table-of-contents">
          <h2>TABLE OF CONTENTS</h2>
          <ol>
            <li>
              <a href="#info-collection">WHAT INFORMATION DO WE COLLECT?</a>
            </li>
            <li>
              <a href="#info-processing">HOW DO WE PROCESS YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#legal-bases">
                WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                INFORMATION?
              </a>
            </li>
            <li>
              <a href="#info-sharing">
                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </li>
            <li>
              <a href="#tracking-technologies">
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </li>
            <li>
              <a href="#social-logins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
            </li>
            <li>
              <a href="#info-retention">
                HOW LONG DO WE KEEP YOUR INFORMATION?
              </a>
            </li>
            <li>
              <a href="#info-safety">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </li>
            <li>
              <a href="#privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>
            <li>
              <a href="#do-not-track">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </li>
            <li>
              <a href="#us-residents-privacy">
                DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a href="#other-regions-privacy">
                DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a href="#updates">DO WE MAKE UPDATES TO THIS NOTICE?</a>
            </li>
            <li>
              <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
            </li>
            <li>
              <a href="#data-review">
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </a>
            </li>
          </ol>
        </div>

        {/* 555555222222233536474765858##################################### */}

        <div className="information-collection">
          <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>

          <p>
            <strong>Personal information you disclose to us</strong>
          </p>

          <p>
            <em>
              In Short: We collect personal information that you provide to us.
            </em>
          </p>

          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>

          <p>
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
          </p>

          <ul>
            <li>Names</li>
            <li>Phone numbers</li>
            <li>Email addresses</li>
            <li>Usernames</li>
            <li>Contact preferences</li>
            <li>Contact or authentication data</li>
            <li>Passwords</li>
          </ul>

          <p>
            <strong>Sensitive Information.</strong> When necessary, with your
            consent or as otherwise permitted by applicable law, we process the
            following categories of sensitive information:
          </p>

          <ul>
            <li>Food preferences</li>
          </ul>

          <p>
            <strong>Social Media Login Data.</strong> We may provide you with
            the option to register with us using your existing social media
            account details, like your Facebook, Twitter, or other social media
            account. If you choose to register in this way, we will collect the
            information described in the section called 'HOW DO WE HANDLE YOUR
            SOCIAL LOGINS?' below.
          </p>

          <p>
            <strong>Application Data.</strong> If you use our application(s), we
            also may collect the following information if you choose to provide
            us with access or permission:
          </p>

          <p>
            <strong>Geolocation Information.</strong> We may request access or
            permission to track location-based information from your mobile
            device, either continuously or while you are using our mobile
            application(s), to provide certain location-based services. If you
            wish to change our access or permissions, you may do so in your
            device's settings.
          </p>

          <p>
            <strong>Mobile Device Access.</strong> We may request access or
            permission to certain features from your mobile device, including
            your mobile device's camera, contacts, SMS messages, social media
            accounts, storage, and other features. If you wish to change our
            access or permissions, you may do so in your device's settings.
          </p>

          <p>
            <strong>Mobile Device Data.</strong> We automatically collect device
            information (such as your mobile device ID, model, and
            manufacturer), operating system, version information and system
            configuration information, device and application identification
            numbers, browser type and version, hardware model Internet service
            provider and/or mobile carrier, and Internet Protocol (IP) address
            (or proxy server). If you are using our application(s), we may also
            collect information about the phone network associated with your
            mobile device, your mobile device’s operating system or platform,
            the type of mobile device you use, your mobile device’s unique
            device ID, and information about the features of our application(s)
            you accessed.
          </p>

          <p>
            <strong>Push Notifications.</strong> We may request to send you push
            notifications regarding your account or certain features of the
            application(s). If you wish to opt out from receiving these types of
            communications, you may turn them off in your device's settings.
          </p>

          <p>
            This information is primarily needed to maintain the security and
            operation of our application(s), for troubleshooting, and for our
            internal analytics and reporting purposes.
          </p>

          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>

          <h3>Information automatically collected</h3>

          <p>
            <strong>In Short:</strong> Some information — such as your Internet
            Protocol (IP) address and/or browser and device characteristics — is
            collected automatically when you visit our Services.
          </p>

          <p>
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>

          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>

          <p>
            <strong>The information we collect includes:</strong>
          </p>

          <ul>
            <li>
              <strong>Log and Usage Data.</strong> Log and usage data is
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Services and which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, and settings and information
              about your activity in the Services (such as the date/time stamps
              associated with your usage, pages and files viewed, searches, and
              other actions you take such as which features you use), device
              event information (such as system activity, error reports
              (sometimes called 'crash dumps'), and hardware settings).
            </li>

            <li>
              <strong>Device Data.</strong> We collect device data such as
              information about your computer, phone, tablet, or other device
              you use to access the Services. Depending on the device used, this
              device data may include information such as your IP address (or
              proxy server), device and application identification numbers,
              location, browser type, hardware model, Internet service provider
              and/or mobile carrier, operating system, and system configuration
              information.
            </li>

            <li>
              <strong>Location Data.</strong> We collect location data such as
              information about your device's location, which can be either
              precise or imprecise. How much information we collect depends on
              the type and settings of the device you use to access the
              Services. For example, we may use GPS and other technologies to
              collect geolocation data that tells us your current location
              (based on your IP address). You can opt out of allowing us to
              collect this information either by refusing access to the
              information or by disabling your Location setting on your device.
              However, if you choose to opt out, you may not be able to use
              certain aspects of the Services.
            </li>

            <li>
              <strong>Contacts data.</strong> With your permission, we access
              your device's contacts to help you connect with friends on our
              App. This information is used solely for this purpose and is not
              shared with third parties for marketing.
            </li>
          </ul>
        </div>

        <div className="information-processing-container">
          <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>

          <p>
            <strong>In Short:</strong> We process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with the law. We may
            also process your information for other purposes with your consent.
          </p>

          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>

          <ul>
            <li>
              <strong>To facilitate account creation and authentication</strong>{" "}
              and otherwise manage user accounts. We may process your
              information so you can create and log in to your account, as well
              as keep your account in working order.
            </li>

            <li>
              <strong>
                To respond to user inquiries/offer support to users.
              </strong>{" "}
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>

            <li>
              <strong>To send administrative information to you.</strong> We may
              process your information to send you details about our products
              and services, changes to our terms and policies, and other similar
              information.
            </li>

            <li>
              <strong>To enable user-to-user communications.</strong> We may
              process your information if you choose to use any of our offerings
              that allow for communication with another user.
            </li>

            <li>
              <strong>To request feedback.</strong> We may process your
              information when necessary to request feedback and to contact you
              about your use of our Services.
            </li>

            <li>
              <strong>
                To send you marketing and promotional communications.
              </strong>{" "}
              We may process the personal information you send to us for our
              marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
              For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?' below.
            </li>

            <li>
              <strong>To deliver targeted advertising to you.</strong> We may
              process your information to develop and display personalized
              content and advertising tailored to your interests, location, and
              more.
            </li>

            <li>
              <strong>To protect our Services.</strong> We may process your
              information as part of our efforts to keep our Services safe and
              secure, including fraud monitoring and prevention.
            </li>

            <li>
              <strong>To identify usage trends.</strong> We may process
              information about how you use our Services to better understand
              how they are being used so we can improve them.
            </li>

            <li>
              <strong>
                To determine the effectiveness of our marketing and promotional
                campaigns.
              </strong>{" "}
              We may process your information to better understand how to
              provide marketing and promotional campaigns that are most relevant
              to you.
            </li>

            <li>
              <strong>
                To save or protect an individual's vital interest.
              </strong>{" "}
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>

          <p>{/* Continue with other sections */}</p>
        </div>

        <div className="legal-bases-container">
          <h2>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </h2>

          <p>
            <strong>In Short:</strong> We only process your personal information
            when we believe it is necessary and we have a valid legal reason
            (i.e. legal basis) to do so under applicable law, like with your
            consent, to comply with laws, to provide you with services to enter
            into or fulfil our contractual obligations, to protect your rights,
            or to fulfil our legitimate business interests.
          </p>

          <p>
            <strong>
              If you are located in the EU or UK, this section applies to you.
            </strong>
          </p>

          <p>
            The General Data Protection Regulation (GDPR) and UK GDPR require us
            to explain the valid legal bases we rely on in order to process your
            personal information. As such, we may rely on the following legal
            bases to process your personal information:
          </p>

          <ul>
            <li>
              <strong>Consent.</strong> We may process your information if you
              have given us permission (i.e. consent) to use your personal
              information for a specific purpose. You can withdraw your consent
              at any time. Learn more about withdrawing your consent.
            </li>

            <li>
              <strong>Performance of a Contract.</strong> We may process your
              personal information when we believe it is necessary to fulfill
              our contractual obligations to you, including providing our
              Services or at your request prior to entering into a contract with
              you.
            </li>

            <li>
              <strong>Legitimate Interests.</strong> We may process your
              information when we believe it is reasonably necessary to achieve
              our legitimate business interests and those interests do not
              outweigh your interests and fundamental rights and freedoms. For
              example, we may process your personal information for some of the
              purposes described in order to:
              <ul>
                <li>
                  Send users information about special offers and discounts on
                  our products and services
                </li>
                <li>
                  Develop and display personalized and relevant advertising
                  content for our users
                </li>
                <li>
                  Analyze how our Services are used so we can improve them to
                  engage and retain users
                </li>
                <li>Support our marketing activities</li>
                <li>Diagnose problems and/or prevent fraudulent activities</li>
                <li>
                  Understand how our users use our products and services so we
                  can improve user experience
                </li>
              </ul>
            </li>

            {/* Continue with other points */}
          </ul>

          <p>
            <strong>
              If you are located in Canada, this section applies to you.
            </strong>
          </p>

          <p>
            We may process your information if you have given us specific
            permission (i.e. express consent) to use your personal information
            for a specific purpose, or in situations where your permission can
            be inferred (i.e. implied consent). You can withdraw your consent at
            any time.
          </p>

          <p>
            In some exceptional cases, we may be legally permitted under
            applicable law to process your information without your consent,
            including, for example:
          </p>

          <ul>
            <li>
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way
            </li>
            <li>For investigations and fraud detection and prevention</li>
            <li>
              For business transactions provided certain conditions are met
            </li>
            <li>
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </li>
            <li>
              For identifying injured, ill, or deceased persons and
              communicating with next of kin
            </li>
            <li>
              If we have reasonable grounds to believe an individual has been,
              is, or may be a victim of financial abuse
            </li>
            <li>
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a breach of an agreement or a contravention of
              the laws of Canada or a province
            </li>
            <li>
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records
            </li>
            <li>
              If it was produced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced
            </li>
            <li>
              If the collection is solely for journalistic, artistic, or
              literary purposes
            </li>
            <li>
              If the information is publicly available and is specified by the
              regulations
            </li>
          </ul>

          <p>{/* Continue with other sections */}</p>
        </div>

        <div className="share-information-container">
          <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

          <p>
            <strong>In Short:</strong> We may share information in specific
            situations described in this section and/or with the following third
            parties.
          </p>

          <p>
            We may need to share your personal information in the following
            situations:
          </p>

          <ul>
            <li>
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>

            <li>
              <strong>When we use Google Maps Platform APIs.</strong> We may
              share your information with certain Google Maps Platform APIs
              (e.g. Google Maps API, Places API). We obtain and store on your
              device ('cache') your location for three (3) months. You may
              revoke your consent anytime by contacting us at the contact
              details provided at the end of this document.
            </li>

            <li>
              <strong>Other Users.</strong> When you share personal information
              (for example, by posting comments, contributions, or other content
              to the Services) or otherwise interact with public areas of the
              Services, such personal information may be viewed by all users and
              may be publicly made available outside the Services in perpetuity.
              If you interact with other users of our Services and register for
              our Services through a social network (such as Facebook), your
              contacts on the social network will see your name, profile photo,
              and descriptions of your activity. Similarly, other users will be
              able to view descriptions of your activity, communicate with you
              within our Services, and view your profile.
            </li>

            <li>
              <strong>Offer Wall.</strong> Our application(s) may display a
              third-party hosted 'offer wall'. Such an offer wall allows
              third-party advertisers to offer virtual currency, gifts, or other
              items to users in return for the acceptance and completion of an
              advertisement offer. Such an offer wall may appear in our
              application(s) and be displayed to you based on certain data, such
              as your geographic area or demographic information. When you click
              on an offer wall, you will be brought to an external website
              belonging to other persons and will leave our application(s). A
              unique identifier, such as your user ID, will be shared with the
              offer wall provider in order to prevent fraud and properly credit
              your account with the relevant reward.
            </li>

            {/* Continue with other points */}
          </ul>

          <p>{/* Continue with other sections */}</p>
        </div>

        <div className="cookies-and-tracking-container">
          <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

          <p>
            <strong>In Short:</strong> We may use cookies and other tracking
            technologies to collect and store your information.
          </p>

          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>

          <h2>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

          <p>
            <strong>In Short:</strong> If you choose to register or log in to
            our Services using a social media account, we may have access to
            certain information about you.
          </p>

          <p>
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>

          <p>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>

          <h2>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

          <p>
            <strong>In Short:</strong> We keep your information for as long as
            necessary to fulfil the purposes outlined in this privacy notice
            unless otherwise required by law.
          </p>

          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than three (3) months past the termination of the user's account.
          </p>

          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymise such
            information, or if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>

          {/* Continue with other sections */}
        </div>

        <div className="privacy-rights-container">
          <h2>9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

          <p>
            <strong>In Short:</strong> In some regions, such as the European
            Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada,
            you have rights that allow you greater access to and control over
            your personal information. You may review, change, or terminate your
            account at any time.
          </p>

          <p>
            In some regions (like the EEA, UK, Switzerland, and Canada), you
            have certain rights under applicable data protection laws. These may
            include the right (i) to request access and obtain a copy of your
            personal information, (ii) to request rectification or erasure;
            (iii) to restrict the processing of your personal information; (iv)
            if applicable, to data portability; and (v) not to be subject to
            automated decision-making. In certain circumstances, you may also
            have the right to object to the processing of your personal
            information. You can make such a request by contacting us by using
            the contact details provided in the section 'HOW CAN YOU CONTACT US
            ABOUT THIS NOTICE?' below.
          </p>

          <p>
            We will consider and act upon any request in accordance with
            applicable data protection laws.
          </p>

          <p>
            If you are located in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your Member State data protection authority or
            UK data protection authority.
          </p>

          <p>
            If you are located in Switzerland, you may contact the Federal Data
            Protection and Information Commissioner.
          </p>

          <h3>Withdrawing your consent:</h3>
          <p>
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section 'HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE?' below or updating your preferences.
          </p>

          <p>
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>

          <h3>Opting out of marketing and promotional communications:</h3>
          <p>
            You can unsubscribe from our marketing and promotional
            communications at any time by clicking on the unsubscribe link in
            the emails that we send, or by contacting us using the details
            provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
            below. You will then be removed from the marketing lists. However,
            we may still communicate with you — for example, to send you
            service-related messages that are necessary for the administration
            and use of your account, to respond to service requests, or for
            other non-marketing purposes.
          </p>

          <h3>Account Information</h3>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
          </ul>

          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>

          <h3>Cookies and similar technologies:</h3>
          <p>
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Services.
          </p>

          <p>
            If you have questions or comments about your privacy rights, you may
            email us at christo.john@delnie.com.
          </p>

          {/* Continue with other sections */}
        </div>
        <div className="do-not-track-features-container">
          <h2>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ('DNT') feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage, no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>

          {/* Continue with other sections */}
        </div>

        <div className="privacy-rights-container">
          <h2>11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
          <p>
            In Short: If you are a resident of California, Colorado,
            Connecticut, Utah, or Virginia, you are granted specific rights
            regarding access to your personal information.
          </p>
          <p>
            <strong>
              What categories of personal information do we collect?
            </strong>
          </p>

          <p>
            We have collected the following categories of personal information
            in the past twelve (12) months:
          </p>
          <table className="data-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Examples</th>
                <th>Collected</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A. Identifiers</td>
                <td>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  B. Personal information as defined in the California Customer
                  Records statute
                </td>
                <td>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under state or
                  federal law
                </td>
                <td>Gender and date of birth</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>D. Commercial information</td>
                <td>
                  Transaction information, purchase history, financial details,
                  and payment information
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>E. Biometric information</td>
                <td>Fingerprints and voiceprints</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>F. Internet or other similar network activity</td>
                <td>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>G. Geolocation data</td>
                <td>Device location</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </td>
                <td>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>I. Professional or employment-related information</td>
                <td>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>J. Education Information</td>
                <td>Student records and directory information</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>K. Inferences drawn from collected personal information</td>
                <td>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>L. Sensitive personal Information</td>
                <td>
                  Account login information, contents of email or text messages
                  and precise geolocation
                </td>
                <td>YES</td>
              </tr>
            </tbody>
          </table>

          <p>
            We will use and retain the collected personal information as needed
            to provide the Services or for:
          </p>
          <ul>
            <li>Category B - As long as the user has an account with us</li>
            <li>Category K - As long as the user has an account with us</li>
            <li>Category L - As long as the user has an account with us</li>
          </ul>

          <p>
            Category L information may be used or disclosed to a service
            provider or contractor for additional, specified purposes. You have
            the right to limit the use or disclosure of your sensitive personal
            information.
          </p>

          <p>
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <ul>
            <li>Receiving help through our customer support channels;</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>

          {/* Continue with other sections */}
          <p>
            <strong>How do we use and share your personal information?</strong>
          </p>

          <p>
            Learn about how we use your personal information in the section,
            'HOW DO WE PROCESS YOUR INFORMATION?'
          </p>

          <p>
            <strong>Will your information be shared with anyone else?</strong>
          </p>

          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Learn more about how we disclose personal information to in the
            section, 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'
          </p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            'selling' of your personal information.
          </p>

          <p>
            We have not disclosed, sold, or shared any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. We will not sell or share personal information
            in the future belonging to website visitors, users, and other
            consumers.
          </p>

          {/* Continue with other sections specific to California, Colorado, Connecticut, Utah, and Virginia residents */}

          <h2>California Residents</h2>

          <p>
            California Civil Code Section 1798.83, also known as the 'Shine The
            Light' law permits our users who are California residents to request
            and obtain from us, once a year and free of charge, information
            about categories of personal information (if any) we disclosed to
            third parties for direct marketing purposes and the names and
            addresses of all third parties with which we shared personal
            information in the immediately preceding calendar year. If you are a
            California resident and would like to make such a request, please
            submit your request in writing to us using the contact information
            provided below.
          </p>

          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with the Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>

          <h2>CCPA Privacy Notice</h2>

          <p>
            This section applies only to California residents. Under the
            California Consumer Privacy Act (CCPA), you have the rights listed
            below.
          </p>

          <p>
            The California Code of Regulations defines a 'residents' as:
            <ul>
              <li>
                every individual who is in the State of California for other
                than a temporary or transitory purpose and
              </li>
              <li>
                every individual who is domiciled in the State of California who
                is outside the State of California for a temporary or transitory
                purpose
              </li>
            </ul>
          </p>

          <p>All other individuals are defined as 'non-residents'.</p>

          <h3>Your rights with respect to your personal data</h3>

          <p>
            <strong>
              Right to request deletion of the data — Request to delete
            </strong>
            <br />
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </p>

          <p>
            <strong>Right to be informed — Request to know</strong>
            <br />
            Depending on the circumstances, you have a right to know:
            <ul>
              <li>whether we collect and use your personal information;</li>
              <li>the categories of personal information that we collect;</li>
              <li>
                the purposes for which the collected personal information is
                used;
              </li>
              <li>
                whether we sell or share personal information to third parties;
              </li>
              <li>
                the categories of personal information that we sold, shared, or
                disclosed for a business purpose;
              </li>
              <li>
                the categories of third parties to whom the personal information
                was sold, shared, or disclosed for a business purpose;
              </li>
              <li>
                the business or commercial purpose for collecting, selling, or
                sharing personal information; and
              </li>
              <li>
                the specific pieces of personal information we collected about
                you.
              </li>
            </ul>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>

          <p>
            <strong>
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights
            </strong>
            <br />
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>

          <p>
            <strong>
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </strong>
            <br />
            If the business collects any of the following:
            <ul>
              <li>
                social security information, drivers' licenses, state ID cards,
                passport numbers
              </li>
              <li>account login information</li>
              <li>
                credit card numbers, financial account information, or
                credentials allowing access to such accounts
              </li>
              <li>precise geolocation</li>
              <li>
                racial or ethnic origin, religious or philosophical beliefs,
                union membership
              </li>
              <li>
                the contents of email and text, unless the business is the
                intended recipient of the communication
              </li>
              <li>genetic data, biometric data, and health data</li>
              <li>data concerning sexual orientation and sex life</li>
            </ul>
            you have the right to direct that business to limit its use of your
            sensitive personal information to that use which is necessary to
            perform the Services.
          </p>

          <p>
            Once a business receives your request, they are no longer allowed to
            use or disclose your sensitive personal information for any other
            purpose unless you provide consent for the use or disclosure of
            sensitive personal information for additional purposes.
          </p>

          <p>
            Please note that sensitive personal information that is collected or
            processed without the purpose of inferring characteristics about a
            consumer is not covered by this right, as well as the publicly
            available information.
          </p>

          <p>
            To exercise your right to limit use and disclosure of sensitive
            personal information, please email{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>{" "}
            or visit:{" "}
            <a href="https://www.favhiker.com/support">
              https://www.favhiker.com/support
            </a>
            .
          </p>

          <h3>Verification Process</h3>

          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>

          <p>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>

          <h3>Other Privacy Rights</h3>

          <p>
            You may object to the processing of your personal information.
            <br />
            You may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            information.
            <br />
            You can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
            <br />
            You may request to opt out from future selling or sharing of your
            personal information to third parties. Upon receiving an opt-out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the date of the request
            submission.
          </p>

          <p>
            To exercise these rights, you can contact us by visiting{" "}
            <a href="https://www.favhiker.com/support">
              https://www.favhiker.com/support
            </a>
            , by email at <a href="mailto:info@delnie.com">info@delnie.com</a>,
            or by referring to the contact details at the bottom of this
            document. If you have a complaint about how we handle your data, we
            would like to hear from you.
          </p>

          <h3>Colorado Residents</h3>

          <p>
            This section applies only to Colorado residents. Under the Colorado
            Privacy Act (CPA), you have the rights listed below. However, these
            rights are not absolute, and in certain cases, we may decline your
            request as permitted by law.
          </p>

          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects ('profiling')
            </li>
          </ul>

          <p>
            To submit a request to exercise these rights described above, please
            email{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>{" "}
            or visit{" "}
            <a href="https://www.favhiker.com/support">
              https://www.favhiker.com/support
            </a>
            .
          </p>

          <p>
            If we decline to take action regarding your request and you wish to
            appeal our decision, please email us at{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>
            . Within forty-five (45) days of receipt of an appeal, we will
            inform you in writing of any action taken or not taken in response
            to the appeal, including a written explanation of the reasons for
            the decisions.
          </p>

          <h3>Connecticut Residents</h3>

          <p>
            This section applies only to Connecticut residents. Under the
            Connecticut Data Privacy Act (CTDPA), you have the rights listed
            below. However, these rights are not absolute, and in certain cases,
            we may decline your request as permitted by law.
          </p>

          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects ('profiling')
            </li>
          </ul>

          <p>
            To submit a request to exercise these rights described above, please
            email{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>{" "}
            or visit{" "}
            <a href="https://www.favhiker.com/support">
              https://www.favhiker.com/support
            </a>
            .
          </p>

          <p>
            If we decline to take action regarding your request and you wish to
            appeal our decision, please email us at{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>
            . Within sixty (60) days of receipt of an appeal, we will inform you
            in writing of any action taken or not taken in response to the
            appeal, including a written explanation of the reasons for the
            decisions.
          </p>

          <h3>Utah Residents</h3>

          <p>
            This section applies only to Utah residents. Under the Utah Consumer
            Privacy Act (UCPA), you have the rights listed below. However, these
            rights are not absolute, and in certain cases, we may decline your
            request as permitted by law.
          </p>

          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
            <li>Right to access your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising or the sale of personal data
            </li>
          </ul>

          <p>
            To submit a request to exercise these rights described above, please
            email{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>{" "}
            or visit{" "}
            <a href="https://www.favhiker.com/support">
              https://www.favhiker.com/support
            </a>
            .
          </p>

          <h3>Virginia Residents</h3>

          <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>

          <ul>
            <li>
              'Consumer' means a natural person who is a resident of the
              Commonwealth acting only in an individual or household context. It
              does not include a natural person acting in a commercial or
              employment context.
            </li>
            <li>
              'Personal data' means any information that is linked or reasonably
              linkable to an identified or identifiable natural person.
              'Personal data' does not include de-identified data or publicly
              available information.
            </li>
            <li>
              'Sale of personal data' means the exchange of personal data for
              monetary consideration.
            </li>
          </ul>

          <p>
            If this definition of 'consumer' applies to you, we must adhere to
            certain rights and obligations regarding your personal data.
          </p>

          <h4>Your rights with respect to your personal data</h4>

          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
            <li>Right to access your personal data</li>
            <li>Right to correct inaccuracies in your personal data</li>
            <li>Right to request deletion of your personal data</li>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects ('profiling')
            </li>
          </ul>

          <p>
            <strong>
              Exercise your rights provided under the Virginia VCDPA
            </strong>
          </p>

          <p>
            You may contact us by email at{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>{" "}
            or visit{" "}
            <a href="https://www.favhiker.com/support">
              https://www.favhiker.com/support
            </a>
            .
          </p>

          <p>
            If you are using an authorised agent to exercise your rights, we may
            deny a request if the authorised agent does not submit proof that
            they have been validly authorised to act on your behalf.
          </p>

          <h4>Verification process</h4>

          <p>
            We may request that you provide additional information reasonably
            necessary to verify you and your consumer's request. If you submit
            the request through an authorised agent, we may need to collect
            additional information to verify your identity before processing
            your request.
          </p>

          <p>
            Upon receiving your request, we will respond without undue delay,
            but in all cases, within forty-five (45) days of receipt. The
            response period may be extended once by forty-five (45) additional
            days when reasonably necessary. We will inform you of any such
            extension within the initial 45-day response period, together with
            the reason for the extension.
          </p>

          <h4>Right to appeal</h4>

          <p>
            If we decline to take action regarding your request, we will inform
            you of our decision and reasoning behind it. If you wish to appeal
            our decision, please email us at{" "}
            <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>
            . Within sixty (60) days of receipt of an appeal, we will inform you
            in writing of any action taken or not taken in response to the
            appeal, including a written explanation of the reasons for the
            decisions. If your appeal is denied, you may contact the Attorney
            General to submit a complaint.
          </p>
        </div>

        <div>
          <h3>12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</h3>

          <p>
            <strong>In Short:</strong> You may have additional rights based on
            the country you reside in.
          </p>
        </div>

        <div>
          <h4>Australia and New Zealand</h4>

          <p>
            We collect and process your personal information under the
            obligations and conditions set by Australia's Privacy Act 1988 and
            New Zealand's Privacy Act 2020 (Privacy Act).
          </p>

          <p>
            This privacy notice satisfies the notice requirements defined in
            both Privacy Acts, in particular: what personal information we
            collect from you, from which sources, for which purposes, and other
            recipients of your personal information.
          </p>

          <p>
            If you do not wish to provide the personal information necessary to
            fulfill their applicable purpose, it may affect our ability to
            provide our services, in particular:
          </p>

          <ul>
            <li>Offer you the products or services that you want</li>
            <li>Respond to or help with your requests</li>
            <li>Manage your account with us</li>
            <li>Confirm your identity and protect your account</li>
          </ul>

          <p>
            At any time, you have the right to request access to or correction
            of your personal information. You can make such a request by
            contacting us by using the contact details provided in the section
            'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?'
          </p>

          <p>
            If you believe we are unlawfully processing your personal
            information, you have the right to submit a complaint about a breach
            of the Australian Privacy Principles to the Office of the Australian
            Information Commissioner and a breach of New Zealand's Privacy
            Principles to the Office of New Zealand Privacy Commissioner.
          </p>
        </div>

        <div>
          <h4>Republic of South Africa</h4>

          <p>
            At any time, you have the right to request access to or correction
            of your personal information. You can make such a request by
            contacting us by using the contact details provided in the section
            'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?'
          </p>

          <p>
            If you are unsatisfied with the manner in which we address any
            complaint with regard to our processing of personal information, you
            can contact the office of the regulator, the details of which are:
          </p>

          <p>
            The Information Regulator (South Africa)
            <br />
            General enquiries:{" "}
            <a href="mailto:enquiries@inforegulator.org.za">
              enquiries@inforegulator.org.za
            </a>
            <br />
            Complaints (complete POPIA/PAIA form 5):{" "}
            <a href="mailto:PAIAComplaints@inforegulator.org.za">
              PAIAComplaints@inforegulator.org.za
            </a>{" "}
            &{" "}
            <a href="mailto:POPIAComplaints@inforegulator.org.za">
              POPIAComplaints@inforegulator.org.za
            </a>
          </p>
        </div>

        <section>
          <h3>13. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
          <p>
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated 'Revised' date, and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </section>

        <section>
          <h3>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
          <p>
            If you have questions or comments about this notice, you may contact
            our Data Protection Officer (DPO), Christy John, by email at{" "}
            <a href="mailto:christy.john@delnie.com">christy.john@delnie.com</a>
            , by phone at +91 9846280132, or contact us by post at:
          </p>
          <address>
            Delnie Private Limited
            <br />
            Attn: Christy John
            <br />
            Delnie Private Limited, Raheja Platinum, Marol CHS Rd, Andheri -
            Kurla Road,
            <br />
            Marol, Andheri East
            <br />
            Mumbai, Maharashtra 400059
            <br />
            India
          </address>
        </section>

        <section>
          <h3>
            15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h3>
          <p>
            You have the right to request access to the personal information we
            collect from you, change that information, or delete it. To request
            to review, update, or delete your personal information, please
            visit:{" "}
            <a href="https://www.favhiker.com/support">
              https://www.favhiker.com/support
            </a>
            .
          </p>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
