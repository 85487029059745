import * as React from "react";
import "./index.scss";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import CustomizedMenus from "../Dropdown/Dropdown";
import Dropdown from "../Dropdown/Dropdown";
import DropdownIcon from "../Dropdown/DropdownIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext, useEffect, useState } from "react";
import AppsDropdown from "../AppsDropdown";
import FavHikerLogo from "../../assets/images/header/logo.svg";
import MenuIcon from "../../assets/images/header/menu-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const profileData = [
  {
    key: "Home",
    value: "Home",
    route: "/",
    href: "#home",
  },
  {
    key: "About Us",
    value: "About Us",
    // route: "/about_us",
    href: "#about",
  },
  {
    key: "Core Features",
    value: "Core Features",
    // route: "/products",
    href: "#core",
  },

  {
    key: "Help & Support",
    value: "Help & Support",
    // route: "/find_dealer",
  },
];

const handleFieldClick = (e: any) => {
  console.log(e.target.value);
};

export default function Navbar(props: any) {
  const navigate = useNavigate();

  const [scrollY, setScrollY] = useState(0);

  const [textColor, setTextColor] = useState("#011323");
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  // Animation controls for the button color change
  const buttonColorControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      // Update the scroll position
      setScrollY(window.scrollY);

      // You can adjust the threshold value based on when you want the color to change
      const threshold = 50;

      // Update button color based on scroll position
      if (window.scrollY > threshold) {
        buttonColorControls.start({
          backgroundColor: "#1A273A",
          border: "1px solid #011323",
          color: "#FFFFFF",
        });
        setTextColor("#FFFFFF");
        setShowBoxShadow(true);
      } else {
        buttonColorControls.start({
          backgroundColor: "transparent",
          color: "#011323",
        });
        setTextColor("#011323");
        setShowBoxShadow(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [buttonColorControls]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleAbout = () => {
    navigate("/about_us");
  };

  const handleProducts = () => {
    navigate("/product-category");
  };

  const handleCareers = () => {
    navigate("/careers");
  };

  const handleDealer = () => {
    navigate("/product_enquiry");
  };

  const handleContact = () => {
    navigate("/contact_us");
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const handleScreen = () => {
    // document.querySelector("body").style.overflow = "auto";
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          style={{ backgroundColor: "black" }}
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <NotificationsIcon />
          {/* </Badge> */}
        </IconButton>
        <p className="para-margin">Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          className="black-background"
          style={{ backgroundColor: "black" }}
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p className="para-margin">Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        className={`navbar-header ${showBoxShadow ? "box-shadow-visible" : ""}`}
        sx={{
          boxShadow: showBoxShadow
            ? "0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06)"
            : "none",
        }}
      >
        <Toolbar className="navbar-height">
          <div className="d-flex d-md-none w-100 justify-content-between align-items-center">
            <Button>
              <img src={FavHikerLogo} alt="" />
            </Button>
            <h3 className="header-logo-text">favhiker</h3>
            <Dropdown
              // name={state?.user?.first_name}
              // name={props.user.first_name}
              menuData={profileData}
              imagePath={MenuIcon}
              color="transparent"
              className="pro-btn-main"
              buttonClass="pro-nav-btn pro-acc-btn navbar-mobile-btn"
              menuClass="menu-navbar"
              // endIcon={<DropdownIcon />}
              // endIcon={<DropdownIcon iconColor="white" />}
              spanclass={"span-spacing"}
              customIcon={<LogoutIcon />}
              customText="Logout"
              // customFunction={handleLogout}
              // profileFunction={handleProfile}
              handleFieldClick={handleFieldClick}
              homeRef={props.homeRef}
              aboutRef={props.aboutRef}
              coreRef={props.coreRef}
              communityRef={props.communityRef}
              rewardRef={props.rewardRef}
              exploreRef={props.exploreRef}
              infoRef={props.infoRef}
              scrollToSection={props.scrollToSection}
            />
          </div>

          <div className="d-md-flex d-none w-100 justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <Button className="navbar-logo-btn">
                <img src={FavHikerLogo} alt="" />
              </Button>
              <h3 className="header-logo-text">favhiker</h3>
            </div>

            <div className="d-flex justify-content-center w-100 align-items-center navbar-btn-container">
              <Button
                className="navbar-btns"
                href="#home"
                onClick={() => props.scrollToSection(props.homeRef)}
              >
                <h4>Home</h4>
              </Button>
              <Button
                className="navbar-btns"
                href="#about"
                onClick={() => props.scrollToSection(props.aboutRef)}
              >
                <h4>About Us</h4>
              </Button>
              <Button
                className="navbar-btns"
                href="https://medium.com/@favhiker"
                target="new"
              >
                <h4>Blogs</h4>
              </Button>

              <Button
                className="navbar-btns"
                href="#core"
                onClick={() => props.scrollToSection(props.coreRef)}
              >
                <h4>Core Features</h4>
              </Button>

              <Button
                className="navbar-btns"
                onClick={() => navigate("/support")}
              >
                <h4>Help & Support</h4>
              </Button>
            </div>
            <motion.div
              style={{ borderRadius: "23px" }}
              animate={buttonColorControls}
            >
              {/* <Button className="download-button" sx={{ color: textColor }}>
                Download now
              </Button> */}
            </motion.div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
