import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Home from "./layout/components/Home";
import Privacy from "./layout/components/Privacy";
import HelpSection from "./layout/components/HelpSupport";
import PrivacyPolicy from "./layout/components/Privacy";
import FavHikerEmail from "./layout/components/FavHiker-Email/enquiry";
import OtpEmail from "./layout/components/FavHiker-Email/otp-verfication-mail";
import RegisterEmail from "./layout/components/FavHiker-Email/register-mail";
import DeleteAccountEmail from "./layout/components/FavHiker-Email/delete-account";
import DeleteOtpEmail from "./layout/components/FavHiker-Email/otp-delete-mail";
import DeleteConfirmEmail from "./layout/components/FavHiker-Email/deleted-confirmation";
import MessageEmail from "./layout/components/FavHiker-Email/message";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/privacy-policy" element={<Privacy />} /> */}
          <Route path="/support" element={<HelpSection />} />
          {/* <Route path="/email" element={<FavHikerEmail />} />
          <Route path="/otp_email" element={<OtpEmail />} />
          <Route path="/register_email" element={<RegisterEmail />} />
          <Route path="/delete_acc_email" element={<DeleteAccountEmail />} />
          <Route path="/delete_acc_otp_email" element={<DeleteOtpEmail />} />
          <Route
            path="/delete_confirm_email"
            element={<DeleteConfirmEmail />}
          />
          <Route path="/message_email" element={<MessageEmail />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
