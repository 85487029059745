"use client";

import React, { useRef } from "react";
import CommunityCircleImg from "../../assets/images/community/join-community-img.png";
import "./index.scss";
import { motion, useScroll, useTransform } from "framer-motion";

function CommunityCircle() {
  const ref = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0 1", "1.33 1"],
  });

  const scaleProgress = useTransform(scrollYProgress, [0, 1], [0.8, 1]);
  const opacityProgress = useTransform(scrollYProgress, [0, 1], [0.6, 1]);

  return (
    // <div
    //   ref={ref}
    //   style={{
    //     scale: scaleProgress,
    //     opacity: opacityProgress,
    //   }}
    // >
    <div className="row community-section-container">
      <div className="col-xs-12 community-section-main">
        <div className="d-md-flex d-block justify-content-center align-items-center community-section-content">
          <div className="community-section-text">
            <h1>Join the Community of Foodies With Circles</h1>
            <h3>
              Joining a community of foodies with Circles could refer to
              participating in a social platform or online community dedicated
              to sharing and discussing food-related content.It can be a place
              where people with a shared interest in food come together to
              connect, learn, and explore their passion for all things culinary.
            </h3>
          </div>
          <img src={CommunityCircleImg} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
    // </div>
  );
}

export default CommunityCircle;
