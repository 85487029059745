import React, { useEffect, useState } from "react";
import FirstFeatureImg from "../../assets/images/core/cf-2.png";
import SecondFeatureImg from "../../assets/images/core/cf-1.png";
import ThirdFeatureImg from "../../assets/images/core/cf-3.png";
import CoreHeadImg from "../../assets/images/core/core-head-image.svg";

import tmOneImg from "../../assets/images/travel-mode/tv-1.svg";
import tmTwoImg from "../../assets/images/travel-mode/tv-2.svg";
import tmThreeImg from "../../assets/images/travel-mode/tv-3.svg";
import tmFourImg from "../../assets/images/travel-mode/tv-4.svg";
import tmFiveImg from "../../assets/images/travel-mode/tv-5.svg";

import "./index.scss";
import { motion, useAnimation } from "framer-motion";

function CoreFeatures() {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 0.9 && !isVisible) {
        setIsVisible(true);
        controls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 0.5, ease: "easeOut" },
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls, isVisible]);

  return (
    <>
      <div className="container-fluid desktop-view">
        <div className="row heading-row">
          <img src={CoreHeadImg} alt="" className="img-fluid core-top-img" />
        </div>

        <div className="row core-section-main">
          <div className="col-lg-6 image-col-left">
            <img
              src={FirstFeatureImg}
              alt=""
              className="img-fluid content-image-left"
              style={{ width: "22.52%" }}
            />
          </div>
          <div className="col-lg-6  core-section-content-col AA">
            <h1>01</h1>
            <h2>Restaurants dishes Highlights</h2>
            <p>
              Explore top restaurant menu in FavHiker, where our passionate food
              community recommends standout dishes for exceptional taste and
              quality
            </p>
          </div>
        </div>
        <div className="row core-section-main">
          <div className="col-lg-6  core-section-content-col BB">
            <h1>02</h1>
            <h2>Profile Match</h2>
            <p>
              Favhiker's Profile Match provides personalized recommendations
              based on user profiles, preferences, and dining history. Connect
              with like-minded food enthusiasts and explore tailored restaurant
              suggestions.
            </p>
          </div>
          <div className="col-lg-6 image-col-right">
            <img
              src={SecondFeatureImg}
              alt=""
              className="img-fluid content-image-right"
              style={{ width: "22.52%" }}
            />
          </div>
        </div>
        <div className="row core-section-main">
          <div className="col-lg-6 image-col-left">
            <img
              src={ThirdFeatureImg}
              alt=""
              className="img-fluid content-image-left"
              style={{ width: "22.52%" }}
            />
          </div>
          <div className="col-lg-6  core-section-content-col AA">
            <h1>03</h1>
            <h2>Favhiker Discussion</h2>
            <p>
              Experience interactive Discussion Page, seek food recommendations,
              local insights, and upvote exceptional culinary tips. Embrace
              community-driven food exploration with Favhiker!
            </p>
          </div>
        </div>
      </div>

      {/* %%%%%%%%%%%%%%%%%%% MOBILE VIEW %%%%%%%%%%%%%%%%%%%%%%%%% */}
      <div className="container-fluid mobile-view">
        <div className="row heading-row">
          <img src={CoreHeadImg} alt="" className="img-fluid core-top-img" />
        </div>

        <div className="row core-section-main">
          <div className="core-section-content-col">
            <h1>01</h1>
            <h2>Restaurants dishes Highlights</h2>
            <img src={FirstFeatureImg} alt="" className="img-fluid" />
            <p>
              Discover highly praised restaurant highlights in Favhiker,
              showcasing beloved dishes recommended by our passionate food
              community for exceptional taste and quality.
            </p>
          </div>
        </div>
        <div className="row core-section-main">
          <div className="core-section-content-col">
            <h1>02</h1>
            <h2>Profile Match</h2>
            <img src={SecondFeatureImg} alt="" className="img-fluid" />
            <p>
              Favhiker's Profile Match provides personalized recommendations
              based on user profiles, preferences, and dining history. Connect
              with like-minded food enthusiasts and explore tailored restaurant
              suggestions.
            </p>
          </div>
        </div>
        <div className="row core-section-main">
          <div className="core-section-content-col">
            <h1>03</h1>
            <h2>Favhiker Discussion</h2>
            <img src={ThirdFeatureImg} alt="" className="img-fluid" />
            <p>
              Experience interactive Discussion Page, seek food recommendations,
              local insights, and upvote exceptional culinary tips. Embrace
              community-driven food exploration with Favhiker!"
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoreFeatures;
