import React, { useEffect, useRef } from "react";
import "./index.scss";
import ThreePhonesBg from "../../assets/images/first-container/three-phones.png";
import FourPhonesBg from "../../assets/images/first-container/four-phones.png";
import AboutImg from "../../assets/images/about-screen/about_img.png";
import AboutImge from "../../assets/images/about-screen/about_img2.svg";
import IosButton from "../../assets/images/home/Ios-Image.svg";
import PlayStoreButton from "../../assets/images/home/PlayStore-img.svg";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

function AboutSection() {
  const h1Ref = useRef<any>(null);
  const pRef = useRef<any>(null);
  const h1Controls = useAnimation();
  const pControls = useAnimation();

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    const h1Element = h1Ref.current;
    const pElement = pRef.current;

    const handleScroll = () => {
      if (h1Element && pElement) {
        // Calculate the scroll threshold (1/4th of page height)
        const threshold = window.innerHeight / 1.3;

        // Get the position of the elements relative to the viewport
        const h1Rect = h1Element.getBoundingClientRect();
        const pRect = pElement.getBoundingClientRect();

        // Start the animation when the h1 element crosses the threshold
        if (h1Rect.top < threshold) {
          h1Controls.start("visible");
        } else {
          h1Controls.start("hidden");
        }

        // Start the animation for the p element with a delay after the h1 animation
        if (h1Rect.top < threshold && pRect.top < threshold) {
          pControls.start("visible");
        } else {
          pControls.start("hidden");
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [h1Controls, pControls]);

  return (
    <>
      <div className="container-fluid about-section-container">
        <div className="row about-left-container">
          <div className="col-xs-12 col-md-6 col-lg-6 about-left-container-col">
            <div className="row  about-description">
              <h1>About Favhiker</h1>
              <p>
                Introducing your personalized culinary companion, redefining
                your city dining experience. Explore hidden gems curated just
                for you, with tailored recommendations that reflect your unique
                taste. Immerse yourself in a journey to savor the city's flavors
                like a local—join us in this delicious adventure today!
              </p>
            </div>

            <div className="row">
              <div className="about-section-buttons">
                <Link
                  to={"https://apps.apple.com/in/app/favhiker/id6466083218"}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={IosButton}
                    alt=""
                    className="img-fluid IosButton"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.delnie.favhiker"
                  }
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={PlayStoreButton}
                    alt=""
                    className="img-fluid PlayStoreButton"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6 about-right-container">
            <img
              src={AboutImg}
              alt=""
              className="img-fluid about-img"
              style={{ width: "23.9%" }}
            />
          </div>
        </div>
      </div>

      {/* $$$$$$$$$$$$$$$$$ MOBILE VIEW $$$$$$$$$$$$$$$$$$$$$ */}
      <div className="container-fluid about-mobile-section-container">
        <div className="row about-left-container">
          <div className="col-xs-12 col-md-6 col-lg-12 about-left-container-col">
            <div className="row  about-description">
              <h1>About Favhiker</h1>
            </div>

            <div className="row about-right-mobile-container">
              <img src={AboutImg} alt="" className="img-fluid about-img" />
            </div>

            <div className="row">
              <div className="about-section-buttons">
                <Link
                  to={"https://apps.apple.com/in/app/favhiker/id6466083218"}
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={IosButton}
                    alt=""
                    className="img-fluid IosButton"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
                <Link
                  to={
                    "https://play.google.com/store/apps/details?id=com.delnie.favhiker"
                  }
                  target="_blank" // Use "_blank" to open in a new tab or window
                  rel="noopener noreferrer"
                >
                  <motion.img
                    src={PlayStoreButton}
                    alt=""
                    className="img-fluid PlayStoreButton"
                    whileHover={{ scale: 1.1 }}
                  />
                </Link>
              </div>
            </div>
            <div className="row  about-description">
              <p>
                Introducing your personalized culinary companion, redefining
                your city dining experience. Explore hidden gems curated just
                for you, with tailored recommendations that reflect your unique
                taste. Immerse yourself in a journey to savor the city's flavors
                like a local—join us in this delicious adventure today!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection;
