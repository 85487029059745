import React from "react";
import "./index.scss";
import HomeImage from "../../../assets/images/home/home-top-image.svg";
import HomeImageMob from "../../../assets/images/home/home-top-image.svg";
import HomeBackground from "../../../assets/images/home/home-bg.png";
import IosButton from "../../../assets/images/home/Ios-Image.svg";
import PlayStoreButton from "../../../assets/images/home/PlayStore-img.svg";
import IosButtonHome from "../../../assets/images/home/App Store.svg";
import PlayStoreButtonHome from "../../../assets/images/home/Google Play.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import YoutubeEmbed from "./YoutubeEmbed";

function FirstSection() {
  return (
    <>
      <div className="container-fluid first-section-container">
        <div className="row">
          <div className="col-lg-5">
            <h1>Taste Award and Connect</h1>
            <h2>Your Social Gateway to the Culinary World</h2>
            {/* <h3>Your Social Gateway to the culinary World</h3> */}

            <div className="first-section-buttons">
              <Link
                to={"https://apps.apple.com/in/app/favhiker/id6466083218"}
                target="_blank" // Use "_blank" to open in a new tab or window
                rel="noopener noreferrer"
              >
                <motion.img
                  src={IosButtonHome}
                  alt=""
                  className="img-fluid IosButton"
                  whileHover={{ scale: 1.1 }}
                />
              </Link>
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.delnie.favhiker"
                }
                target="_blank" // Use "_blank" to open in a new tab or window
                rel="noopener noreferrer"
              >
                <motion.img
                  src={PlayStoreButtonHome}
                  alt=""
                  className="img-fluid PlayStoreButton"
                  whileHover={{ scale: 1.1 }}
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-7 favhiker-embed-video">
            <YoutubeEmbed embedId="Kh5MWK07qYs" />
          </div>
        </div>
      </div>
      <div className="container-fluid first-section-mobile-container">
        <div className="row first-section-mobile-text">
          <h1>Taste Award and Connect</h1>
          <h2>Your Social Gateway to the Culinary World</h2>
          {/* <h3>Your Social Gateway to the culinary World</h3> */}
        </div>
        <div className="row favhiker-mobile-embed-video mt-3">
          <YoutubeEmbed embedId="Kh5MWK07qYs" />
        </div>

        <div className="row first-section-mobile-buttons">
          <Link
            to={"https://apps.apple.com/in/app/favhiker/id6466083218"}
            target="_blank" // Use "_blank" to open in a new tab or window
            rel="noopener noreferrer"
          >
            <motion.img
              src={IosButtonHome}
              alt=""
              className="img-fluid IosButton"
              whileHover={{ scale: 1.1 }}
            />
          </Link>
        </div>
        <div className="row first-section-mobile-buttons">
          <Link
            to={
              "https://play.google.com/store/apps/details?id=com.delnie.favhiker"
            }
            target="_blank" // Use "_blank" to open in a new tab or window
            rel="noopener noreferrer"
          >
            <motion.img
              src={PlayStoreButtonHome}
              alt=""
              className="img-fluid PlayStoreButton"
              whileHover={{ scale: 1.1 }}
            />
          </Link>
        </div>
      </div>
    </>
  );
}

export default FirstSection;
