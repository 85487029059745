import React from "react";
import ExploreImg from "../../assets/images/explore/explore-page-image.png";
import "./index.scss";
import iPhoneOne from "../../assets/images/Goldenbites/iPhoneOne.png";
import iPhoneTwo from "../../assets/images/Goldenbites/iPhoneTwo.png";
import GoldenBiteImg from "../../assets/images/Goldenbites/goldenbite-img.png";
import GoldenBiteIcon from "../../assets/images/Goldenbites/GoldenBite.svg";

function GoldenBites() {
  return (
    <div className="container-fluid goldenbites-container">
      <div className="row ">
        <div className="col-xs-12 col-lg-6 goldenbites-content">
          <div className="goldenbites-heading">
            <h1 className="d-flex align-items-center">
              <span>GoldenBites</span>
              {/* <img src={GoldenBiteImg} alt="" /> */}
            </h1>
            <h2>- a symbol of distinction</h2>
          </div>

          {/* <div className="col-xs-12 col-lg-6  goldenbites-images goldenbites-images-mobile">
            <img
              src={GoldenBiteIcon}
              alt=""
              className="img-fluid iphone-image-mobile"
            />
          </div> */}
          <p>
            Acknowledge your favourite dishes by awarding them prestigious
            GoldenBites. <br /> Experience the GoldenBites shared by others.
          </p>
        </div>

        <div className="col-xs-12 col-lg-6  goldenbites-images goldenbites-images-desktop">
          <img
            src={GoldenBiteIcon}
            alt=""
            className="img-fluid iphone-image-desktop"
          />
        </div>
      </div>
    </div>
  );
}

export default GoldenBites;
